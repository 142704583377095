import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"bs-container pa-0",attrs:{"fluid":""}},[_c('OnboardingNavBar'),_c('div',{staticClass:"main"},[_c('div',{staticClass:"nav-header"},[_c('div',{staticClass:"nav-header-icon backIcon"},[_c('div',{staticClass:"nav-icon",on:{"click":function($event){return _vm.$router.push({path: '/onboarding/client/get-started'})}}},[_c('img',{staticClass:"nav-icon-styles",attrs:{"src":require("@/assets/svg/nav-back.svg"),"alt":"arrow left"}})])]),_c('div',{staticClass:"nav-header-content"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"legend"},[_vm._v("I need content for")])])])]),_c('div',{staticClass:"main-content"},[_c(VRadioGroup,{attrs:{"hide-details":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,staticClass:"all-list",attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c(VRadio,{attrs:{"value":item.name,"color":"secondary1"}},[_c('template',{staticClass:"label",slot:"label"},[_c('span',{staticClass:"checkboxTitle"},[_vm._v(" "+_vm._s(item.title)+" ")])])],2)],1),_c('span',{staticClass:"hint"},[_vm._v(_vm._s(item.description))])],1)],1)}),1),(_vm.errors.type)?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message"},[_vm._v("Select Content")])])])]):_vm._e()],1),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"content-main-actions d-flex justify-end"},[_c('div',{staticClass:"action-container"},[_c('div',{staticClass:"continue-btn-wrapper d-flex flex-column"},[_c('div',{staticClass:"bs-divider"}),_c(VBtn,{staticClass:"continue-btn",attrs:{"depressed":""},on:{"click":_vm.submit}},[_vm._v(" Continue ")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }