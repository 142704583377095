<template>
    <!-- eslint-disable -->
    <v-container fluid class="bs-container pa-0">
        <OnboardingNavBar/>
        <div class="main">
            <div class="nav-header">
                <div class="nav-header-icon backIcon">
                    <div class="nav-icon" v-on:click="$router.push({path: '/onboarding/client/get-started'})">
                        <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                    </div>
                </div>
                <div class="nav-header-content">
                    <div class="text">
                        <div class="legend">I need content for</div>
                    </div>
                </div>
            </div>
            <div class="main-content">
                <v-radio-group
                 v-model="type"     
                hide-details
              >
               <v-list-item class="all-list" two-line v-for="(item, index) in items" :key="index">
          <v-list-item-content>
            <v-list-item-title >
            <v-radio :value="item.name" color="secondary1">
            <template slot="label" class="label">
              <span class="checkboxTitle">
              {{item.title}}
              </span>
            </template>
            </v-radio>
            </v-list-item-title>
                <span class="hint">{{item.description}}</span>            
            </v-list-item-content>
      </v-list-item>
       </v-radio-group>
       <div class="v-text-field__details" v-if="errors.type">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">Select Content</div>
                                </div>
                            </div>
                        </div>
            </div>
            <div class="divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                      <div class="bs-divider"></div>
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submit"
                        >
                            Continue
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OnboardingNavBar from '../creative/OnboardingNavBar';

export default {
  name: 'Content',
  components: {
    OnboardingNavBar,
  },
  data() {
    return ({
      errors: {},
      type: '',
      items: [
        {
          name: 'E-Commerce',
          description: 'I have physical products or a retail store to sell. ',
          title: 'My E-Commerce Business',
        },
        {
          name: 'Professional Services',
          // eslint-disable-next-line quotes
          description: `I am in a service based business. I'm a realtor, doctor, dentist, etc.`,
          title: 'My Professional Services Business',
        },
        {
          name: 'Technology',
          // eslint-disable-next-line quotes
          description: `I’m in a tech startup`,
          title: 'My Technology Startup',
        },
      ],
    });
  },
  async mounted() {
    this.type = await localStorage.getItem('business_type');
  },
  methods: {
    isValidState() {
      const errors = {};
      if (!this.type) {
        errors.type = 'content is required';
      } else {
        delete errors.type;
      }
      this.errors = { ...errors };
      return Object.values(this.errors).length === 0;
    },
    async submit() {
      const isValid = this.isValidState();
      // generate payload
      if (!isValid) return;
      localStorage.setItem('business_type', this.type);
      this.$router.push({ path: `/onboarding/client/about-business?type=${this.type}` });
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-radio{
  padding:10px;
  overflow: visible;
}
.hint {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #929292;
  overflow:visible;
  height: 50px;
  margin-top: -0.5rem;
  margin-left: 3rem;
}
.all-list{
  margin-bottom: -2rem;
}
.checkboxTitle {
  font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #262626;
}
::v-deep.v-input--selection-controls .v-radio > .v-label{
  max-width: 100px !important;
}
.v-list-item {
  padding: 0;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    padding: 2rem 4rem;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.nav-icon-styles{
  margin-top:15px;
  margin-left:-10px;
}
.nav-header-icon {
    margin-right: 2rem;
    margin-top: 1rem;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 46px;
    margin-top: 10px;
    margin-left:3rem;
    margin-bottom: 1.5rem;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.main-content {
    margin-left: 5rem;
}
.section-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin: 1rem 0rem;
}
.section-input-title{
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.equipment-col {
    max-width: 450px;
}
.proficiency-checkbox {
    margin-bottom: 1rem !important;
}

.continue-btn-wrapper{
    max-width: 200px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.gender-select {
    max-width: 200px;
    margin-top: 0.3rem;
}
.ethnicity-select {
    max-width: 350px;
    margin-top: 0.3rem;
}
.backIcon {
  cursor: pointer;
}
.divider{
  margin-bottom: 1rem;
  border-top: 1px solid #DEDEDE;
  width: 100%;
  margin-top: 8rem;
}
@media screen and (min-device-width:0px) and (max-device-width: 1000px) {
  .content-main {
   padding-left: 0px;
  }
  .nav-icon-styles{
    width:8px;
    height: 16px;
    margin-top:-30px;
  }
  .main {
    padding: 2rem;
    width: 100%;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
    margin-left: 1.5rem;
    margin-top:10px;
    margin-bottom: 1.5rem;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .main-content {
    margin-left: 2rem
  }
  .hint{
    max-width:226.25px;
  }
  .all-list{
    margin-bottom: 5px;
  }
  .continue-btn-wrapper {
    margin-top: 20rem;
    max-width:335px !important;
  }
  .content-main-actions {
      justify-content: center !important;
      padding-right: 0px;
  }
  .bs-divider {
    width:335px !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-top: 1px solid #DEDEDE;
  }
  .divider{
    display: none;
  }
}
</style>
