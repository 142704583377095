<template>
  <!-- eslint-disable -->
  <v-container fluid class='bs-container pa-0'>
    <OnboardingNavBar />
    <div class='content-main'>
      <div class='content-main-info'>
        <div class='content-main-inner-inner'>
          <div class='content-left'>
            <div class='content'>
              <div class='greeting-text'>
                Thanks for applying to the network
              </div>
              <div class='note-item'>
                <div class='note-item-text'>
                  Looks like your talent was not quite what we were looking for this round. We will let you know when you can reapply again.
                </div>
              </div>
              <div class='note-item'>
                <div class='note-item-text'>
For any other questions, look at our FAQ’s below or reach out to hello@blendedsense.com.
                </div>
              </div>
               <div class="content-main-actions d-flex">
            <div class="action-container">
                <div class="continue-btn-wrapper d-flex flex-column">
                    
                     <a class="continue-btn" href='mailto:hello@blendedsense.com'>
                      Contact
                     </a>
                </div>
            </div>
        </div>
            </div>
          </div>
          <div class='content-right'>
            <v-img
              src='@/assets/svg/not-a-good-match.svg'
              contain
              width='234'
              height='191'
            />
          </div>
        </div>
        
                  <v-row class="faq">
                   <v-col sm=12 md=8 lg=8 xl=8 class="questions" :key="questions.length">
              <p class="section-title">Frequently Asked Questions</p>
              <v-expansion-panels
              class="description que-description"
                  v-for="(item, i) in questions"
                  :key="i">
                <v-expansion-panel class="que-div">
                  <v-expansion-panel-header class="text">
                    {{item.question}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    class="sub-text expansion-panel-sub-text"
                  >
                    {{item.answer}}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'CreativeGetStarted',
  components: {
    OnboardingNavBar,
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    questions() {
      return [
        {
          question: 'When can I reapply to the creative network?',
          answer: 'You are able to reapply to the network from your account after 6 months.',
        },
        {
          question: 'Who reviews my content and accepts my application?',
          answer: 'the blended sense production team reviews you content and the production manager approves the application',
        },
      ];
    },
  },
  mounted() {
    if (Number(this.userDetails.onboardingState) < 11 || !(this.userDetails.status === '4')) {
      this.$router.push({
        path: '/',
      });
    }
  },
};
</script>

<style lang='scss' scoped>
.note-item-text {
  //styleName: Body/Large - Desktop;
  font-family: $fontFamily1;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
}
.bs-container {
  flex-direction: column;
  min-height: 100vh;
  background: white;
}
.content-main {
  max-width: 1290px;
  margin: auto;
  padding: 2rem;
}
.content-right {
    align-items: center;
    display: flex;
    padding-left: 30px;
    padding-bottom: 45px;
}
.content-main-actions {
  margin-top: 2rem;
}
.content-main-inner-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.greeting-text {
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 63px;
  color: #343838;
}
.note-item {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  .note-item-icon {
    margin-right: 1rem;
  }
}
.bs-divider {
  margin: 2rem 0rem;
  border-top: 1px solid #dedede;
}
.content {
  max-width: 530px;
  min-width: 400px;
}
.note-items {
  margin-top: 2rem;
}
.actions {
  margin-top: 2rem;
}
.action-container {
  min-width: 400px;
  display: flex;
}
.continue-btn-wrapper {
  width: 186px;
  color: #262626;
  .btn-note {
    margin-top: 0.5rem;
    color: #262626;
    font-family: $fontFamily1;
  }
}
.continue-btn {
  width: 100%;
  border-radius: 1.5rem;
  height: 44px;
  padding: 0.5rem 2rem !important;
  background: #FFFFFF;
  border: 2px solid #41E0BA;
  font-size: 18px;
  color: #262626;
  text-align: center;
  .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    color: #262626;
    font-family: $fontFamily1;
  }
}
.name {
  word-break: break-word;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  font-weight: 600;
  font-family: $fontFamily1;
}

.title {
  margin: -7px;
}
.faq {
  display: flex;
  justify-content: center;
}

.questions {
    padding:21px;
    padding-top: 45px;
}
 .v-expansion-panel {
        background: none;
        border-bottom: 1px solid #D8D8D8;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 15px;
        &::before {
          box-shadow: none !important;
        }
      }
      .v-expansion-panel-header {
        padding: 0px !important;
        min-height: auto;
      }
      ::v-deep .v-expansion-panel-content .v-expansion-panel-content__wrap {
        padding: 0 0 15px 0 !important;
        font-size: 16px !important;
        line-height: 20px !important;
      }
      .section-title {
        font-family: $fontFamily1;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 8px;
        margin-bottom: 19px;
        color: #262626;

      }
       .que-description{
        .que-div {
          // margin-bottom: 30px;
         .text {
font-family: $fontFamily1;
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #000000;
            margin-bottom: 12px !important;
          }
          .sub-text{
            font-size: 16px !important;
font-family: $fontFamily1;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #555555;

          }
        }
      }
      .text-div {
        margin-right: 30px;
      }
      .text {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #343838;
        margin-bottom: 10px;
      }
      .sub-text {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #555555;
      }
@media screen and (max-width: 900px) {
  .content-main-inner-inner {
    flex-direction: column;
    align-items: center;
  }
  .content-main-actions {
    justify-content: center !important;
  }
  .resource {
    width: 350px;
  }
}
</style>
