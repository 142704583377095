<template>
  <!-- eslint-disable -->
  <v-container fluid class='bs-container pa-0'>
    <OnboardingNavBar />
    <div class='content-main'>
      <div class='content-main-info'>
        <div class='content-main-inner-inner'>
          <div class='content-left'>
            <div class='content'>
              <div class='greeting-text'>
                Your application is being reviewed
              </div>
              <div class='note-item'>
                <div class='note-item-text'>
                  Our team is still reviewing your application. While you wait,
                  check out some of the links below:
                </div>
              </div>
            </div>
          </div>
          <div class='content-right'>
            <v-img
              src='@/assets/svg/application-review.svg'
              contain
              width='234'
              height='191'
            />
          </div>
        </div>
        
                  <div class='resources'>
                    <div class='description'>
                      <div
                        class='d-flex resource'
                        v-for='(resource, i) in resources'
                        :key='i'
                      >
                        <div class='d-flex'>
                          <img
                            :src='resource.icon'
                            style='width:28px; height: 32px; margin-right: 18px;'
                          />
                          <div class='title'>
                            <div class='name'>{{ resource.name }}</div>
                            <div class='desc'>{{ resource.description }}</div>
                          </div>
                        </div>
                        <div class="linkNameclass">
                           <a :href="resource.linkName === 'Contact Support'
                      ? `mailto:${resource.link}` : resource.link "
                      target="_blank" class="linkName">{{resource.linkName}}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-row class="faq">
                   <v-col sm=12 md=8 lg=8 xl=8 class="questions" :key="questions.length">
              <p class="section-title">Frequently Asked Questions</p>
              <v-expansion-panels
              class="description que-description"
                  v-for="(item, i) in questions"
                  :key="i">
                <v-expansion-panel class="que-div">
                  <v-expansion-panel-header class="text">
                    {{item.question}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    class="sub-text expansion-panel-sub-text"
                  >
                    {{item.answer}}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
// import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'CreativeGetStarted',
  components: {
    OnboardingNavBar,
  },
  methods: {
    ...mapActions('profile', ['getProfile']),
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    resources() {
      return [
        {
          name: 'Videos & Tutorials',
          description: 'Check out our YouTube channel for tips and tricks.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/tutorial.svg'),
          link: 'http://youtube.com/channel/UCqrj6OYDNzdjTZ71XBrNh0Q/featured',
          linkName: 'Visit YouTube Channel',
        },
        {
          name: 'Visit Resource Center',
          description:
            'Check out our resources for more on all things creative.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/resourceCenter.svg'),
          link: 'https://www.blendedsense.com/blog',
          linkName: 'Visit Blog',
        },
        {
          name: 'Technical Support',
          description: 'Have questions? Reach out to your producer directly.',
          // eslint-disable-next-line global-require
          icon: require('@/assets/svg/support.svg'),
          link: 'support@blendedsense.com',
          linkName: 'Contact Support',
        },
      ];
    },
    questions() {
      return [
        {
          question: 'When will I hear back on my status?',
          answer: 'It will take our dedicated production team around 5 days to get you your results back.',
        },
        {
          question: 'What if my application is declined this round?',
          answer: 'No worries! Please wait 6 months to apply again into the network.',
        },
        {
          question: 'How will I be notifiied of my application status?',
          answer: 'You will recieve an email with your results after 5 business days from our Creative Community Manager.',
        },
      ];
    },
  },
  async mounted() {
    await this.getProfile();
    if (Number(this.userDetails.onboardingState) < 11 || !(this.userDetails.status === '0')) {
      this.$router.push({
        path: '/dashboard',
      });
    }
  },
};
</script>

<style lang='scss' scoped>
.note-item-text {
  //styleName: Body/Large - Desktop;
  font-family: $fontFamily1;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
}
.bs-container {
  flex-direction: column;
  min-height: 100vh;
  background: white;
}
.content-main {
  max-width: 1290px;
  margin: auto;
  padding: 2rem;
}
.content-main-actions {
  margin-top: 2rem;
}
.content-main-inner-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.greeting-text {
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 63px;
  color: #343838;
}
.note-item {
  display: flex;
  align-items: center;
  padding: 1rem 0rem;
  .note-item-icon {
    margin-right: 1rem;
  }
}
.bs-divider {
  margin: 2rem 0rem;
  border-top: 1px solid #dedede;
}
.content {
  max-width: 530px;
  min-width: 400px;
}
.note-items {
  margin-top: 2rem;
}
.actions {
  margin-top: 2rem;
}
.action-container {
  min-width: 400px;
  display: flex;
  justify-content: center;
}
.continue-btn-wrapper {
  max-width: 270px;
  .btn-note {
    margin-top: 0.5rem;
    color: #929292;
    font-family: $fontFamily1;
  }
}
.continue-btn {
  width: 100%;
  border-radius: 1.5rem;
  height: 44px;
  padding: 0.5rem 2rem !important;
  background: $secondary1 !important;
  color: white;
  .v-btn__content {
    font-size: 16px;
    line-height: 28px;
  }
}
.name {
  word-break: break-word;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  font-weight: 600;
  font-family: $fontFamily1;
}
.desc {
  color: #555555;
  padding-top: 2px;
  //styleName: Body/Normal;
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.resource {
  margin: 15px 0px;
  width: 750px;
  height: 108px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
  align-items: center !important;
  justify-content: space-between;
  padding: 10px;
}
.title {
  margin: -7px;
}
.faq {
  display: flex;
  justify-content: center;
}
.linkName {
  font-family: $fontFamily1;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border-bottom: 3px solid #41e0ba;
  color: #555555;
  cursor: pointer;
}
.resources {
    display: flex;
    justify-content: center;
    margin-top: 56px;
}
.questions {
    padding:45px;
}
 .v-expansion-panel {
        background: none;
        border-bottom: 1px solid #D8D8D8;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 15px;
        &::before {
          box-shadow: none !important;
        }
      }
      .v-expansion-panel-header {
        padding: 0px !important;
        min-height: auto;
      }
      ::v-deep .v-expansion-panel-content .v-expansion-panel-content__wrap {
        padding: 0 0 15px 0 !important;
        font-size: 16px !important;
        line-height: 20px !important;
      }
      .section-title {
        font-family: $fontFamily1;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 8px;
        margin-bottom: 19px;
        color: #262626;

      }
       .que-description{
        .que-div {
          // margin-bottom: 30px;
         .text {
font-family: $fontFamily1;
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color: #000000;
            margin-bottom: 12px !important;
          }
          .sub-text{
            font-size: 16px !important;
font-family: $fontFamily1;
font-weight: 400;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #555555;

          }
        }
      }
      .text-div {
        margin-right: 30px;
      }
      .text {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #343838;
        margin-bottom: 10px;
      }
      .sub-text {
        font-family: $fontFamily1;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 24px;
        color: #555555;
      }
@media screen and (max-width: 900px) {
  .content-main-inner-inner {
    flex-direction: column;
    align-items: center;
  }
  .content-main-actions {
    justify-content: center !important;
  }
  .resource {
    width: 370px;
  }
  .desc{
    width:230px;
  }
  .linkNameclass{
    padding:0.5rem;
  }
}
</style>
