<!-- eslint-disable -->
<template>
  <v-container fluid class="bs-container pa-0">
    <OnboardingNavBar />
      <div class="content-main">
        <div class="content-main-info">
            <div class="greeting-text">
                Hang tight, we will be right with you.
            </div>
            <div class="content-main-inner-inner">
                <div class="content-left">
                    <div class="content">
                    
                    <div class="note-items">
                        <div class="content">
                            <div class="note-heading">
                                 Heres what happens next:
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-text">
                              Our team of Production experts reviews and responds within 5 business days.
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-text">
                              For now, check out <a class='text-purple'  target='_blank' href="https://www.instagram.com/blendedsense/">instagram</a> and <a class='text-purple'  target='_blank' href="https://www.youtube.com/channel/UCqrj6OYDNzdjTZ71XBrNh0Q?view_as=subscriber">youtube</a> for some creative content!
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <v-img
              src='@/assets/svg/hangTight.svg'
              contain
              width='234'
              height='191'
            />
                </div>
            </div>
            <div class="divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                      <div class="bs-divider"></div>
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submit"
                        >
                           Go to Dashboard
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'CreativeNextSteps',
  components: {
    OnboardingNavBar,
  },
  methods: {
    ...mapActions('onboarding', ['saveFinish']),
    ...mapActions('profile', ['getSavedProfile']),
    async submit() {
      this.loading = true;
      const response = await this.saveFinish();
      this.loading = false;
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  async mounted() {
    await this.getSavedProfile();
    // eslint-disable-next-line
    if (Number(this.userDetails.onboardingState) < 10 || Number(this.userDetails.onboardingState) === 10) {
      this.$router.push({
        path: '/',
      });
    }
  },
};
</script>

<style lang="scss" scoped>

.note-heading {
font-family: $fontFamily1;
font-size: 24px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: #262626;
padding-bottom: 25px;

}
.note-item-text {
font-family: $fontFamily1;
font-size: 20px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: left;
color: #262626;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.content-main {
    max-width: 1290px;
    margin: auto;
    padding: 2rem;
    display: flex;
    justify-content: center;
}
.content-main-actions {
    margin-top: 2rem;
}
.content-main-inner-inner {
    display: flex;
    flex-wrap: wrap;
}
.greeting-text {
font-family: $fontFamily2;
font-size: 46px;
font-weight: 800;
line-height: 58px;
letter-spacing: 0em;
text-align: left;
color: #262626;

}
.note-item {
    display: flex;
    align-items: center;
    padding: 5px 0rem;
    .note-item-icon {
        margin-right: 1rem
    }
}
.content {
    max-width: 630px;
    min-width: 400px;
}
.note-items {
    margin-top: 2rem;
}
.actions {
    margin-top: 2rem;
}
.action-container {
    min-width: 400px;
    display: flex;
    justify-content: flex-end;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.content-left {
    flex: 1;
}
.content-right {
    display: flex;
    justify-content: flex-end;
    padding-top: 80px;
}
.illustration-container {
    height: 395px;
    background: rgb(229, 229, 229);
    width: 350px;
    margin-top: 40px;
}
.illustration{
}
.divider{
  margin-top: 10rem;
  margin-bottom: 1rem;
  width:100%;
  border-top: 1px solid #dedede;
}
@media screen and (max-width: 900px) {
    .content-main-inner-inner {
        flex-direction: column;
        align-items: center;
    }
    .content-main-actions {
        justify-content: center !important;
    }
}
@media screen and (max-width: 600px) {
  .content-main {
    margin-left: 6rem;
  }
  .main {
    justify-content: center;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
  }
  .note-heading{
    width:300px;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .continue-btn-wrapper{
    padding-right: 10px;
  }
  .action-container {
    align-items: center;
  }
  .greeting-text {
    font-family: $fontFamily2;
    width:312px;
    font-size: 30px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #262626;
  }
  .note-item-text{
    width:300px;
  }
  .continue-btn-wrapper{
    max-width: 100%;
  }
  .continue-btn{
    width:335px;
    margin-left:-8rem !important;
    margin-right:0rem !important;
  }
  .content-main-actions{
    margin-left:-8rem;
    display: flex;
    justify-content: center !important;
  }
  .content-right{
    display: none;
  }
  .bs-divider{
    width:340px;
    border-top: 1px solid #dedede;
    margin-bottom: 1rem;
    margin-left: -8rem;
    margin-right: 0rem;
    margin-top: 15rem;
  }
  .divider{
    display: none;
  }
}
</style>
