<template>
<!-- eslint-disable max-len -->
  <v-container fluid class="bs-container pa-0">
    <OnboardingNavBar style="max-width: 100%;"/>
      <div class="content-main">
        <div class="content-main-info">
            <div class="content-main-inner-inner">
                <div class="content-left">
                    <div class="content">
                    <div class="greeting-text">
                        Hey {{userDetails.firstName}}, start your experience with Blended Sense
                    </div>
                    <div class="note-items">
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img width='30' class="icon" style="margin-top:-5px;" src="@/assets/svg/document_check.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text" style="margin-top:1rem;">
                                Start building your account by answering some questions about your business
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img width='30' class="icon" src="@/assets/svg/two_people.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text">
                                Get matched with a producer by ordering your assets
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img width='30' class="icon" src="@/assets/svg/call.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text">
                                Schedule a creative vision call with your dedicated content producer
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img width='30' class="icon" src="@/assets/svg/gallery.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text">
                                After your sweep, recieve assets for your business
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <v-img
              src='@/assets/svg/get-started_bs.svg'
              width='300'
            />
                </div>
            </div>
            <div class="divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                    <div class="bs-divider"></div>
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submitForm"
                        >
                            Get Started
                        </v-btn>
                        <div class="btn-note">
                            <!-- eslint-disable-next-line -->
                            It takes 5-15 minutes to complete your profile, and we save it as you go.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from '../creative/OnboardingNavBar';

export default {
  name: 'ClientGetStarted',
  components: {
    OnboardingNavBar,
  },
  methods: {
    ...mapActions('user', ['creativeGetStarted']),
    async submitForm(e) {
      e.preventDefault();
      const response = await this.creativeGetStarted();
      if (response.success) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push('/onboarding/client/content-preferences');
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
};
</script>

<style lang="scss" scoped>
.content-right {
  margin-top: 7rem;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.content-main {
    padding: 5rem;
    margin-left:4rem;
}
.content-main-actions {
    margin-top: 2rem;
}
.content-main-inner-inner {
    display: flex;
    flex-wrap: wrap;
}
.greeting-text {
    width:724px;
    height: 116px;
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    font-size: 46px;
    line-height: 58px;
    color: #262626;
}
.note-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0rem;
    margin-top: 0.5rem;
    .note-item-icon {
        margin-right: 3rem;
        margin-top: 1rem;
    }
}
.content {
    max-width: 660px;
    min-width: 400px;
}
.note-items {
    margin-top: 2rem;
}
.actions {
    margin-top: 2rem;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.content-left {
    flex: 1;
}
.content-right {
    min-width: 400px;
    padding-top: 10px;
}
.illustration-container {
    height: 100%;
    background: rgb(229, 229, 229);
    min-height: 400px;
}
.illustration{
}
.divider{
    margin-top: 10rem;
    border-top: 1px solid #dedede;
    width:100%;
    margin-bottom: 1rem;
}
@media screen and (max-width: 900px) {
    .content-main-inner-inner {
        flex-direction: column;
        align-items: center;
    }
    .content-main-actions {
        justify-content: center !important;
    }
}
@media screen and (min-width: 0px) and (max-width:1000px) {
    .greeting-text {
        width: 314px;
        height: 114px;
        font-family: $fontFamily2;
        font-style: normal;
        font-weight: 800;
        font-size: 30px;
        line-height: 38px;
        color: #262626;
    }
    .content-right {
        display: none;
    }
    .content {
        min-width: 0px;
    }
    .content-main {
        padding: 3rem;
        margin-left:0.5rem;
    }
    .content-main-inner-inner {
        flex-direction: column;
        align-items: center;
    }
    .continue-btn-wrapper {
        max-width: 312px;
    }
    .content-main-actions {
        justify-content: center !important;
        padding-right: 0px;
    }
    .note-item-icon{
        margin-top: 0rem !important;
        margin-right:1rem !important;
    }
    .btn-note{
        font-size: 13px;
        min-width: 315px;
    }
    .icon{
        width: 16.67px;
        height: 20px;
        margin-bottom: 1rem;
    }
    .note-item-text{
        width: 251px;
        margin-bottom: 0.5rem;
        margin-left: 0.3rem;
    }
    .bs-divider {
        margin: 0rem 0rem;
        border-top: 1px solid #dedede;
        width:100%;
        margin-top: 11rem;
        margin-bottom: 1rem;
    }
    .divider{
        display: none;
    }
}
</style>
