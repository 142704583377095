<template>
  <v-container fluid class="bs-container pa-0">
    <OnboardingNavBar style="max-width:100%;"/>
      <div class="content-main">
        <div class="content-main-info">
            <div class="content-main-inner-inner">
                <div class="content-left">
                    <div class="content">
                    <div class="greeting-text">
                        Hey {{userDetails.firstName}}. Ready to get matched to local gigs?
                    </div>
                    <div class="note-items">
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img src="@/assets/svg/document_check.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text">
                                Build your profile and tell us what kind of Creative Pro you are.
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img src="@/assets/svg/two_people.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text">
                                Submit your application for review.
                                You will hear back within 5 business days.
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img src="@/assets/svg/brush_cc.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text">Once accepted,
                                keep your Availability up to date to start receiving Sweep
                                invitations when matched with a local business!
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-icon">
                                <img src="@/assets/svg/dollar_2.svg" alt="note icon" />
                            </div>
                            <div class="note-item-text">
                                We track your pay and direct deposit on the 15th every month.
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <v-img
              src='@/assets/svg/matchToGigis.svg'
              width='300'
            />
                </div>
            </div>
            <div class="divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                        <div class="bs-divider"></div>
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submitForm"
                        >
                            Get Started
                        </v-btn>
                        <div class="btn-note">
                            <!-- eslint-disable-next-line -->
                            It takes 5-15 minutes to complete your profile, and we save it as you go.
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from './OnboardingNavBar';

export default {
  name: 'CreativeGetStarted',
  components: {
    OnboardingNavBar,
  },
  methods: {
    ...mapActions('user', ['creativeGetStarted']),
    ...mapActions('profile', ['getSavedProfile']),
    async submitForm(e) {
      e.preventDefault();
      const response = await this.creativeGetStarted();
      if (response.success) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'creative',
        });
        if (nextStep.url) {
          this.$router.push({
            path: nextStep.url,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  mounted() {
    this.getSavedProfile();
  },
};
</script>

<style lang="scss" scoped>
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.content-main {
    max-width: 1290px;
    margin: auto;
    padding: 6rem;
}
.content-main-inner-inner {
    display: flex;
    flex-wrap: wrap;
}
.greeting-text {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    width:400px;
    line-height: 48px;
    color: #262626;
}
.note-item {
    display: flex;
    padding: 1rem 0rem;
    .note-item-icon {
        margin-right: 2rem;
    }
}
.content {
    max-width: 600px;
    min-width: 600px;
}
.note-items {
    line-height: 24px;
    font-style: normal;
    margin-top: 2rem;
    width: 613px;
    font-family: $fontFamily1;
    font-weight: 400;
}
.note-item-text{
    margin-left: 2rem;
}
.actions {
    margin-top: 2rem;
}
.continue-btn-wrapper{
    max-width: 270px;
    margin-right: 0rem;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.content-left {
    flex: 1;
}
.content-right {
    min-width: 400px;
    padding-top: 10px;
}
.illustration-container {
    height: 100%;
    background: rgb(229, 229, 229);
    min-height: 400px;
}
.illustration{
}
.divider{
    border-top: 1px solid #dedede;
    width:100%;
    margin-bottom: 1rem;
    margin-top:2rem;
}
@media screen and (max-width: 900px) {
    .content-main-inner-inner {
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 600px) {
    .greeting-text {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    width:315px;
    font-size: 30px;
    line-height: 38px;
    color: #262626;
    }
    .content-right {
        display: none;
    }
    .content {
        min-width: 0px;
    }
    .content-main {
        padding: 3rem;
    }
    .note-items{
        width: 280px;
    }
    .note-item-text{
        margin-left:0.5rem;
        margin-bottom: 0rem;
    }
    .bs-divider {
        margin-top: 2rem;
        margin-bottom: 1rem;
        border-top: 1px solid #DEDEDE;
        width:100%;
    }
    .content-main-inner-inner {
        flex-direction: column;
        align-items: center;
    }
    .continue-btn-wrapper{
    max-width: 100%;
    margin-right: 0rem;
    }
    .continue-btn{
    width:325px;
    margin-top: 0.5rem;
    margin-left:0rem !important;
    margin-right:0rem !important;
    margin-top: 0.5rem;
    }
    .content-main-actions{
    display: flex;
    justify-content: center !important;
    }
    .divider{
        display: none;
    }
}
</style>
