<template>
<!-- eslint-disable -->
<v-container fluid class="bs-container pa-0">
    <OnboardingNavBar/>
    <div v-if="loading" class="loader">
      <img
          src="@/assets/svg/theme/bs_logo.gif"
          class="loader_gif"
      />
    </div>
    <div class="main" v-else>
        <div class="nav-header">
            <div class="nav-header-icon">
                <div class="nav-icon backIcon" v-on:click="$router.push({path: '/onboarding/client/content-preference'})">
                    <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                </div>
            </div>
            <div class="nav-header-content">
                <div class="text">
                    <div class="legend">Tell us about your <p class="break-title"> business </p></div>
                </div>
            </div>
        </div>
        <div class="main-content">
            <div class="profile-pic-section">
                <div class="profile-pic-input-container ms-3">
                    <div class="profile-pic-input">
                        <div class="profile-pic-preview-container">
                            <div class="preview">
                                <img :src="profilePreviewImage" alt="profile pic" />
                            </div>
                        </div>
                    </div>
                    <div class="profile-pic-button">
                        <v-btn
                            class="profile-pic-upload-btn"
                            color="#262626"
                            dark
                            large
                            depressed
                            outlined
                            @click="openFileCropper"
                        >
                        Upload Photo
                        </v-btn>
                    </div>
                </div>
            </div>
            <div class="profile-info-section">
                <div class="profile-info-input-section">
                    <div class="input-content mt-4">
                        <div class="input-content-link">
                            <div class="input-content-title">
                                <div class="legend">Business Name<sup>*</sup></div>
                            </div>
                            <v-text-field
                                v-model="businessName"
                                outlined
                                dense
                                class="location-input"
                            ></v-text-field>
                        </div>
                        <div class="input-content-link">
                            <div class="input-content-title">
                                <div class="legend">Website</div>
                            </div>
                            <v-text-field
                                v-model="website"
                                outlined
                                dense
                                class="location-input"
                                :error-messages="errors.instagram"
                            ></v-text-field>
                        </div>
                         <div class="input-content-link mb-3" v-if="isEcom">
                            <div class="input-content-title">
                                <div class="legend mb-2">What type of physical product do you sell?<sup>*</sup></div>
                            </div>
                            <v-radio-group v-model="typeOfService">
                            <v-radio
                                value="Toys, kids, and baby"
                                label="Toys, kids, and baby"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Accessories"
                                label="Accessories"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Foods & beverage"
                                label="Foods & beverage"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Beauty"
                                label="Beauty"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Home, garden, and tools"
                                label="Home, garden, and tools"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Other"
                                label="Other"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                             <v-text-field
                             :class="{ 'border-red': otherServiceErr }"
                              class="contact-input other-input-field"
                              solo
                              dense
                              outlined
                              flat
                              v-model="otherService"
                              v-if="typeOfService === 'Other'"
                            >
                            </v-text-field>
                        </v-radio-group>
                        </div>
                         <div class="input-content-link" v-if="isTech">
                            <div class="input-content-title mb-2" >
                                <div class="legend">What type of technology do you sell?<sup>*</sup></div>
                            </div>
                            <v-radio-group v-model="typeOfService">
                            <v-radio
                                value="Hardware"
                                label="Hardware"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Software"
                                label="Software"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Both"
                                label="Both"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                        </v-radio-group>
                        </div>
                        <div class="input-content-link" v-if="isPro">
                            <div class="input-content-title mb-2">
                                <div class="legend">What type of services do you offer<sup>*</sup></div>
                            </div>
                            <v-radio-group v-model="typeOfService">
                            <v-radio
                                value="Legal Services"
                                label="Legal Services"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Real Estate"
                                label="Real Estate"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Financial"
                                label="Financial"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Medical"
                                label="Medical"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Home"
                                label="Home"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Coaching"
                                label="Coaching"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Fitness"
                                label="Fitness"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Hospitality"
                                label="Hospitality"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Other"
                                label="Other"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                        </v-radio-group>
                            <v-text-field
                             :class="{ 'border-red': otherServiceErr }"
                              class="contact-input other-input-field"
                              solo
                              dense
                              outlined
                              flat
                              v-model="otherService"
                              v-if="typeOfService === 'Other'"
                            >
                            </v-text-field>
                        </div>
                                               
                </div>
                        <div class="profile-info-input-section">
                    <div class="input-content-title">
                        <div class="legend">Where do you provide services?</div>
                    </div>
                    <div class="input-content">
                        <v-row class="rowDisplay">
                            <v-col>
                                <!-- :class="{'errorState v-input--has-state error--text':
                                    errors('location').length}" -->
                                <div

                              v-for="(location, index) in locations"
                              :key="location.key"
                                class="v-input input-field v-input--is-label-active v-input--is-dirty
                                v-input--dense theme--light v-text-field v-text-field--single-line
                                v-text-field--solo v-text-field--solo-flat v-text-field--is-booted
                                v-text-field--enclosed v-text-field--outlined errorState"
                                >
                                <div class="v-input__control">
                                    <div class="v-input__slot">
                                    <fieldset aria-hidden="true">
                                        <legend><span>&#8203;</span></legend>
                                    </fieldset>
                                    <div class="v-text-field__slot">
                              <div class="v-input__control">
                                <div class="v-input__slot">
                                  <div class="v-text-field__slot">
                                    <vue-google-autocomplete
                                      :id="location.key"
                                      v-on:inputChange="(data) => setLocation(data, location.key)"
                                      :types="['establishment']"
                                      :component-restrictions="null"
                                      :value="location.value"
                                      class="location-field"
                                    >
                                    </vue-google-autocomplete>
                                  </div>
                                </div>
                              </div>
                              <v-icon
                                v-if="canShow(index)"
                                class="close cursor-pointer"
                                @click="removeLocation(location.key)"
                                >mdi-close</v-icon
                              >
                                    </div>
                                    </div>
                                    <div class="v-text-field__details" v-if="errors.city">
                                        <div class="v-messages theme--light error--text" role="alert">
                                            <div class="v-messages__wrapper">
                                            <div class="v-messages__message">Please provide valid locations</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                              <div class="addLocation" @click="addLocation">+ Add Location</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        <div class="divider"></div>
        <div class="content-main-actions d-flex justify-end" v-if="!loading">
            <div class="action-container">
                <div class="continue-btn-wrapper d-flex flex-column">
                  <div class="divider2"></div>
                    <v-btn
                        class="continue-btn"
                        depressed
                        @click="onSubmit"
                    >
                        Continue
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
    <HyperCropper ref="hyperCropper" />
</v-container>
</template>

<script>
/* eslint-disable no-extra-boolean-cast */
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import HyperCropper from '@/plugins/HyperCropper/Index';
import usStates from '@/helpers/us_states.json';
import { isValidURL } from '@/helpers/index';
import profileFallbackImage from '@/assets/svg/camera-filled.svg';
import OnboardingNavBar from '../creative/OnboardingNavBar';

export default {
  name: 'Business',
  components: {
    VueGoogleAutocomplete,
    OnboardingNavBar,
    HyperCropper,
  },
  data() {
    return ({
      loading: false,
      profileImage: null,
      locations: [],
      errors: {},
      contentType: '',
      typeOfService: '',
      locationErrors: false,
      otherServiceErr: false,
      website: '',
      businessName: '',
      avatar: null,
    });
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    profilePreviewImage() {
      if (typeof this.profileImage === 'string') {
        return this.profileImage || profileFallbackImage;
      }
      if (this.profileImage) {
        return URL.createObjectURL(this.profileImage);
      }
      return profileFallbackImage;
    },
    isEcom() {
      return this.contentType === 'E-Commerce';
    },
    isPro() {
      return this.contentType === 'Professional Services';
    },
    isTech() {
      return this.contentType === 'Technology';
    },
  },
  methods: {
    ...mapActions('profile', ['getSavedProfile']),
    ...mapActions('onboarding', ['setOnboardingBusinessProfile']),
    ...mapActions('brandProfile', ['getBrandProfile']),
    checkOtherService() {
      const { otherService } = this;
      if (otherService) {
        this.otherServiceErr = false;
      } else {
        this.otherServiceErr = true;
      }
    },
    async onSubmit() {
      const {
        profileImage,
        businessName,
        website,
        typeOfService,
        otherService,
        contentType,
        locations,
      } = this;
      this.isSavingData = true;
      const formData = new FormData();
      formData.append('avatar', profileImage);
      formData.append('businessName', businessName);
      formData.append('website', website);
      formData.append('typeOfService', typeOfService);
      formData.append('other', otherService);
      formData.append('businessType', contentType);
      formData.append('locations', JSON.stringify(locations));
      this.loading = true;
      const response = await this.setOnboardingBusinessProfile(formData);
      this.isSavingData = false;
      if (response) {
        this.loading = false;
        this.$router.push('/onboarding/client/customer');
      }
      this.loading = false;
    },
    canShow(index) {
      if (index === 0) {
        return false;
      }
      return true;
    },
    addLocation() {
      this.locations.push({ key: `location-${Date.now()}`, value: '' });
    },
    removeLocation(key) {
      this.locations = this.locations.filter((loc) => loc.key !== key);
      this.locationErr();
    },
    async setLocation(location, key) {
      const loc = this.locations.find((l) => l.key === key);
      if (loc) {
        loc.value = location.newVal.trim();
      }
      this.locations = [...this.locations];
      this.locationErr();
    },
    locationErr() {
      const locationsArr = _.filter(this.locations, (loc) => loc.value !== '');
      this.locationErrors = locationsArr.length === 0;
    },
    async openFileCropper() {
      try {
        const croppedFile = await this.$refs.hyperCropper.open();
        this.profileImage = croppedFile;
      } catch (err) {
        // operation cancelled. do nothing
      }
    },
    getStateFromShortName(shortName) {
      return usStates[shortName];
    },
    back() {
      this.$router.push({
        path: '/onboarding/client/content-preference',
      });
    },
    onAddressInputChanged(event) {
      const [city, state] = event.newVal.split(',');
      this.city = city.trim();
      const target = this.$refs.cityRef;
      target.update(this.city);
      this.state = this.getStateFromShortName(state.trim());
    },
    onPlaceChanged(event, place) {
      const target = this.$refs.cityRef;
      target.update(place.name);
    },
    isValidState() {
      const errors = {};
      if (!this.month) {
        errors.month = 'Please select a month';
      } else {
        delete errors.month;
      }
      if (!this.day) {
        errors.day = 'Please select a day';
      } else {
        delete errors.day;
      }
      if (!this.year) {
        errors.year = 'Please select a year';
      } else {
        delete errors.year;
      }
      if (!this.city) {
        errors.city = 'City is required';
      } else {
        delete errors.city;
      }
      if (!this.zipcode) {
        errors.zipcode = 'Zipcode is required';
      } else if (this.zipcode && !/^(\d{5}|\d{5}-\d{4})$/.test(this.zipcode)) {
        errors.zipcode = 'Invalid zipcode';
      } else {
        delete errors.zipcode;
      }
      if (!this.state) {
        errors.state = 'State is required';
      } else {
        delete errors.state;
      }
      if (!this.skills.length) {
        errors.skills = 'Skills are required';
      } else {
        delete errors.skills;
      }
      if (!this.website) {
        errors.website = 'Website is required';
      } else if (this.website && !isValidURL(this.website)) {
        errors.website = 'Invalid URL';
      } else {
        delete errors.website;
      }
      if (this.instagram && !isValidURL(this.instagram)) {
        errors.instagram = 'Invalid URL';
      } else {
        delete errors.instagram;
      }
      if (this.youtube && !isValidURL(this.youtube)) {
        errors.youtube = 'Invalid URL';
      } else {
        delete errors.youtube;
      }
      this.errors = { ...errors };
      return Object.values(this.errors).length === 0;
    },
  },
  async mounted() {
    const contentType = this.$route.query.type;
    if (!['E-Commerce', 'Professional Services', 'Technology'].includes(contentType)) {
      this.$router.push({ path: '/onboarding/client/content-preferences' });
    }
    await this.getSavedProfile();
    if (Number(this.userDetails.onboardingState) < 3) {
      this.$router.push({
        path: '/',
      });
    }
    this.contentType = contentType;
    this.locations.push({ key: `location-${Date.now()}`, value: '' });
    const businessId = _.get(this.userDetails, 'currentBusinessId', false);
    if (businessId) {
      const data = await this.getBrandProfile({ businessId, reference: 'business' });
      this.businessName = data.brandProfile.business.name || data.brandProfile.name;
      this.website = data.brandProfile.website;
      this.profileImage = data.brandProfile.business.bannerImage;
      // eslint-disable-next-line
      this.locations = _.map(data.brandProfile.locations, (loc) => ({ key: loc.id, value: loc.address }));
      this.typeOfService = _.get(data.brandProfile, 'typeOfService.name');
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-input__slot{
  margin-bottom: 0px !important;
  .v-text-field__slot {
    width: 500px !important;
  }
}
::v-deep .v-label {
  color: #262626;
}
::v-deep.v-input--selection-controls .v-radio > .v-label{
  max-width: fit-content !important;
}
::v-deep.theme--dark.v-btn:focus::before {
    opacity: 0.24;
    border-radius: 2rem;
}
::v-deep.theme--dark.v-btn:hover::before{
  border-radius:2rem !important;
}
.radio-button{
  max-width:280px !important;
}
.loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100px;
    height: 100px;
  }
}
.location-input{
  width:626px;
}
.profile-info-section {
  min-width: 400px;
}
.backIcon {
  cursor: pointer;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    padding: 2rem 4rem;
    margin: auto;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.nav-icon-styles{
  margin-top:15px;
  margin-left:-10px;
}
.nav-header-icon {
    margin-right: 8rem;
    margin-top: 1rem;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 46px;
    margin-top:1rem;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.main-content {
    margin-top: 2rem;
    margin-left: 8rem;
    display: flex;
}
.profile-pic-section {
    margin-right: 5rem;
}
::v-deep.v-btn:not(.v-btn--round).v-size--large{
  height: 30px;
}
.profile-pic-upload-btn {
    border-radius: 2rem;
    border: 2px solid #41E0BA;
    background-color: white;
    padding: 0.5rem 2.5rem !important;
    margin-top: 1rem;
    & > .v-btn__content {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.profile-pic-preview-container {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #DEDEDE;
    overflow: hidden;
    & .preview img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.profile-info-input-title .legend {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #262626;
}
.profile-info-input-section {
    margin-bottom: 2rem;
}
.skill-selection-checkbox {
    margin: 0.5rem 0 !important;
    color: $secondary1 !important;
    & > .v-label {
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.input-content-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #262626;
}
.input-content-title {
    // margin-top: 1rem;
}
.input-content-link {
    // margin-top: 0.5rem;
}
.input-content-title .legend {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width:350px;
    color: #262626;
}
.actions {
    margin-top: 2rem;
}
.addLocation {
  cursor: pointer;
  // width:fit-content;
  color: $secondary1;
  padding-top: 15px;

}
.continue-btn-wrapper{
  max-width: 270px;
  margin-right: 1.5rem;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width:100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.month-input {
    width: 100%;
}
.state-input {
    width: 300px;
}
.break-title{
    margin-top: -67px;
    margin-left:28rem;
}
.divider{
  margin-bottom: 1rem;
  border-top: 1px solid #DEDEDE;
  max-width: 100%;
  margin-left:2rem;
  margin-right: 2rem;
}
@media screen and (min-device-width:330px) and (max-device-width: 1000px) {
  ::v-deep.v-input__slot{
    .v-text-field__slot {
      width: 200px !important;
    }
  }
  .rowDisplay {
    display: block;
    margin-right: 1rem;
  }
  .nav-icon-styles{
    width:8px;
    height: 16px;
    margin-top:0.2rem;
  }
  .break-title{
    margin-top: 1rem;
    margin-left:0rem;
  }
  .main-content {
    display: block;
    margin-left: 0.5rem;
  }
  .profile-pic-section{
    margin-right: 1rem;
    justify-content: center;
    display: flex;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
  .main {
    padding:5px 5px;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
    margin-left:1rem;
  }
  .profile-info-section {
    padding: 30px;
    min-width: 200px;
  }
  .subtext {
    padding-top: 12px
  }
  .input-content-title .legend{
    width:315px;
  }
  .continue-btn-wrapper{
    max-width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .continue-btn{
    width:325px;
    margin-left:0px !important;
    margin-right:0px !important;
    margin-bottom: 2rem;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .section-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin: 1rem 0rem;
}
  .section-input-title{
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  .divider2{
  margin-bottom: 1rem;
  border-top: 1px solid #DEDEDE;
  width:100%;
  }
  .divider{
    display: none;
  }
}
</style>
