import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"bs-container pa-0",attrs:{"fluid":""}},[_c('OnboardingNavBar'),_c('div',{staticClass:"content-main"},[_c('div',{staticClass:"content-main-info"},[_c('div',{staticClass:"content-main-inner-inner"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"greeting-text"},[_vm._v(" Your application is being reviewed ")]),_c('div',{staticClass:"note-item"},[_c('div',{staticClass:"note-item-text"},[_vm._v(" Our team is still reviewing your application. While you wait, check out some of the links below: ")])])])]),_c('div',{staticClass:"content-right"},[_c(VImg,{attrs:{"src":require("@/assets/svg/application-review.svg"),"contain":"","width":"234","height":"191"}})],1)]),_c('div',{staticClass:"resources"},[_c('div',{staticClass:"description"},_vm._l((_vm.resources),function(resource,i){return _c('div',{key:i,staticClass:"d-flex resource"},[_c('div',{staticClass:"d-flex"},[_c('img',{staticStyle:{"width":"28px","height":"32px","margin-right":"18px"},attrs:{"src":resource.icon}}),_c('div',{staticClass:"title"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(resource.name))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(resource.description))])])]),_c('div',{staticClass:"linkNameclass"},[_c('a',{staticClass:"linkName",attrs:{"href":resource.linkName === 'Contact Support'
                    ? ("mailto:" + (resource.link)) : resource.link,"target":"_blank"}},[_vm._v(_vm._s(resource.linkName))])])])}),0)]),_c(VRow,{staticClass:"faq"},[_c(VCol,{key:_vm.questions.length,staticClass:"questions",attrs:{"sm":"12","md":"8","lg":"8","xl":"8"}},[_c('p',{staticClass:"section-title"},[_vm._v("Frequently Asked Questions")]),_vm._l((_vm.questions),function(item,i){return _c(VExpansionPanels,{key:i,staticClass:"description que-description"},[_c(VExpansionPanel,{staticClass:"que-div"},[_c(VExpansionPanelHeader,{staticClass:"text"},[_vm._v(" "+_vm._s(item.question)+" ")]),_c(VExpansionPanelContent,{staticClass:"sub-text expansion-panel-sub-text"},[_vm._v(" "+_vm._s(item.answer)+" ")])],1)],1)})],2)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }