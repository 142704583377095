<template>
    <!-- eslint-disable -->
    <v-container fluid class="bs-container pa-0">
        <OnboardingNavBar/>
        <div class="main">
            <div class="nav-header">
                <div class="nav-header-icon backIcon">
                    <div class="nav-icon" v-on:click="$router.go(-1)">
                        <img src="@/assets/svg/nav-back.svg" class="nav-icon-styles" alt="arrow left" />
                    </div>
                </div>
                <div class="nav-header-content">
                    <div class="text">
                        <div class="legend">Tell us about your customer</div>
                    </div>
                </div>
            </div>
            <div class="main-content">
                <div class="general-details-section mt-8">
                    <div class="section-title">
                        Who is your customer?<sup>*</sup>
                    </div>
                    <div class="section-content">
                        <v-radio-group v-model="customer">
                            <v-radio
                                value="Consumers"
                                label="Consumers"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Businesses"
                                label="Businesses"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Both"
                                label="Both"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                            <v-radio
                                value="Not Sure"
                                label="Not Sure"
                                hide-details
                                class="radio-button"
                                color="secondary1"
                            ></v-radio>
                        </v-radio-group>
                        <div class="v-text-field__details" v-if="errors.customer">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">Please select type of customer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="proficient-details-section mt-8">
                    <div class="section-title mb-0">What age is your customer?<sup>*</sup></div>
                    <div class="subtitle mb-4">Check all that apply</div>
                    <div class="proficiency-list">
                            <v-checkbox
                                v-model="ages"
                                label="Gen A (ages 0-6)"
                                value="Gen A (ages 0-6)"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="ages"
                                label="Gen Z (ages 7-24)"
                                value="Gen Z (ages 7-24)"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="ages"
                                label="Millennials (ages 25-40)"
                                value="Millennials (ages 25-40)"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="ages"
                                label="Gen X (ages 41-56)"
                                value="Gen X (ages 41-56)"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <v-checkbox
                                v-model="ages"
                                label="Baby Boomers (ages 57-76)"
                                value="Baby Boomers (ages 57-76)"
                                hide-details
                                color="secondary1"
                                class="proficiency-checkbox"
                            ></v-checkbox>
                            <div class="v-text-field__details" v-if="errors.ages">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                <div class="v-messages__message">Please select Ages of customer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                      <div class="bs-divider"></div>
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submit"
                        >
                            Complete Profile
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import OnboardingNavBar from '../creative/OnboardingNavBar';

export default {
  name: 'SweepDetails',
  components: {
    OnboardingNavBar,
  },
  data() {
    return ({
      ages: [],
      customer: '',
      errors: {},
    });
  },
  methods: {
    ...mapActions('onboarding', ['getOnboardingUserProfile', 'saveCustomerAges']),
    ...mapActions('profile', ['getSavedProfile']),
    isValidState() {
      const errors = {};
      if (!this.customer) {
        errors.customer = 'Customer is required';
      } else {
        delete errors.customer;
      }
      if (!this.ages.length) {
        errors.ages = 'Ages is required';
      } else {
        delete errors.age;
      }
      this.errors = { ...errors };
      return Object.values(this.errors).length === 0;
    },
    async submit() {
      const isValid = this.isValidState();
      if (!isValid) return;
      const formData = {};
      formData.targetCustomerAges = this.ages;
      formData.targetCustomers = this.customer;
      this.loading = true;
      const response = await this.saveCustomerAges(formData);
      this.loading = false;
      if (response) {
        this.$router.push('/onboarding/client/finish');
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  async mounted() {
    await this.getSavedProfile();
    // eslint-disable-next-line
    if (Number(this.userDetails.onboardingState) < 5 || Number(this.userDetails.onboardingState) > 5) {
      this.$router.push({
        path: '/',
      });
    }
    this.pastDuties = _.get(this.userDetails, 'profile.aboutpast', '');
    this.experience = _.get(this.userDetails, 'profile.experience', '');
    this.proficiencies = _.get(this.userDetails, 'profile.fieldProficient', '').split(';');
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-label {
  max-width: fit-content !important;
  color: #262626;
}
::v-deep.v-input--selection-controls .v-radio > .v-label{
  max-width: fit-content !important;
}
::v-deep.v-input--checkbox[data-v-2c8d651a] .v-label{
  max-width: fit-content;
}
.v-input--checkbox ::v-deep {
  .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  max-width: fit-content !important;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #262626;
  }
}
.v-radio ::v-deep {
  .v-label {
    font-family: $fontFamily1;
    max-width: fit-content !important;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #262626;
  }
}
.subtitle {
  color: #929292;
  font-family: $fontFamily1;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.main {
    padding: 2rem 4rem;
}
.nav-header {
    display: flex;
    // align-items: center;
}
.nav-header-icon {
    margin-right: 4rem;
    margin-top: 1.5rem;
}
.nav-header .text .legend {
    font-family: $fontFamily2;
    font-style: normal;
    font-weight: 800;
    // font-size: 2rem;
    font-size: 46px;
}
.nav-header .text .subtext {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
}
.main-content {
    margin-left: 5rem;
}
.section-title {
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    margin: 1rem 0rem;
}
.section-input-title{
    font-family: $fontFamily1;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.proficiency-checkbox {
    margin-bottom: 1rem !important;
}

.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.past-duties-textarea {
    border: 1px solid #E0E0E0 !important;
    border-radius: 0.5rem;
    outline: none !important;
    width: 100%;
    resize: none;
    padding: 0.5rem;
}
.backIcon {
  cursor: pointer;
}
.divider{
  border-top: 1px solid #DEDEDE;
  width: 100%;
  margin-bottom: 1rem;
}
@media screen and (max-width: 600px) {
  .content-main {
   padding-left: 0px;
  }
  .main {
    padding: 2rem;
    width: 100%;
  }
  .nav-icon-styles{
    width:10px;
    height: 20px;
    margin-top: 0.2rem;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
    margin-top:1rem;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .main-content {
    margin-left: 2rem;
  }
  .continue-btn-wrapper{
    max-width: 100%;
  }
  .continue-btn{
    width:325px;
    margin-left:0px !important;
    margin-right:0px !important;
  }
  .content-main-actions{
    display: flex;
    justify-content: center !important;
  }
  .bs-divider {
    margin: 1rem 0rem;
    margin-top: 8rem;
    border-top: 1px solid #DEDEDE;
  }
  .divider{
    display: none;
  }
}
</style>
