<!-- eslint-disable -->
<template>
  <v-container fluid class="bs-container pa-0">
    <OnboardingNavBar/>
      <div class="content-main">
        <div class="content-main-info">
            <div class="greeting-text">
                Welcome to Blended Sense.
            </div>
            <div class="content-main-inner-inner">
                <div class="content-left">
                    <div class="content">
                    
                    <div class="note-items">
                        <div class="content">
                            <div class="note-heading">
                                 Heres what happens next:
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-text mb-3">
                              Go to your dashboard and order your first assets. Once submitted, we can match you to your producer and schedule a CV call.
                            </div>
                        </div>
                        <div class="note-item">
                            <div class="note-item-text">
                              Need more Blended Sense? check out our <a class='text-purple'  target='_blank' href="https://www.instagram.com/blendedsense/">instagram</a> and <a class='text-purple'  target='_blank' href="https://www.youtube.com/channel/UCqrj6OYDNzdjTZ71XBrNh0Q?view_as=subscriber">youtube</a> for some creative content!
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
            <div class="bs-divider"></div>
            <div class="content-main-actions d-flex justify-end">
                <div class="action-container">
                    <div class="continue-btn-wrapper d-flex flex-column">
                        <v-btn
                            class="continue-btn"
                            depressed
                            @click="submit"
                        >
                           Go to Dashboard
                        </v-btn>
                    </div>
                </div>
            </div>
            </div>
        </div>
      </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getNextOnboardingStepInfo } from '@/helpers/onboarding';
import OnboardingNavBar from '../creative/OnboardingNavBar';

export default {
  name: 'CreativeNextSteps',
  components: {
    OnboardingNavBar,
  },
  methods: {
    ...mapActions('profile', ['getSavedProfile']),
    ...mapActions('onboarding', ['finishCustomerSteps']),
    async submit() {
      this.loading = true;
      const response = await this.finishCustomerSteps();
      this.loading = false;
      if (response) {
        const nextStep = getNextOnboardingStepInfo({
          onboardingState: response.nextState,
          role: 'client',
        });
        if (nextStep.url) {
          this.$router.push({
            path: '/dashboard',
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
  },
  async mounted() {
    await this.getSavedProfile();
    if (Number(this.userDetails.onboardingState) < 7) {
      this.$router.push({
        path: '/',
      });
    }
  },
};
</script>

<style lang="scss" scoped>

.note-heading {
font-family: $fontFamily1;
font-size: 24px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0em;
text-align: left;
color: #262626;
padding-bottom: 25px;

}
.note-item-text {
font-family: $fontFamily1;
font-size: 20px;
font-weight: 400;
line-height: 28px;
letter-spacing: 0em;
text-align: left;
color: #262626;
}
.bs-container {
    flex-direction: column;
    min-height: 100vh;
    background: white;
}
.content-main {
    margin: auto;
    padding: 2rem;
    display: flex;
    justify-content: center;
}
.content-main-actions {
    margin-top: 2rem;
}
.content-main-inner-inner {
    display: flex;
    flex-wrap: wrap;
}
.greeting-text {
font-family: $fontFamily2;
font-size: 46px;
font-weight: 800;
line-height: 58px;
letter-spacing: 0em;
text-align: left;
color: #262626;

}
.note-item {
    display: flex;
    align-items: center;
    padding: 5px 0rem;
    .note-item-icon {
        margin-right: 1rem
    }
}
.bs-divider {
    margin: 2rem 0rem;
    border-top: 1px solid #DEDEDE;
    margin-top: 10rem;
}
.content {
    max-width: 630px;
    min-width: 400px;
}
.note-items {
    margin-top: 2rem;
}
.actions {
    margin-top: 2rem;
}
.action-container {
    min-width: 400px;
    display: flex;
    justify-content: flex-end;
}
.continue-btn-wrapper{
    max-width: 270px;
    .btn-note {
        margin-top: 0.5rem;
        color: #929292;
        font-family: $fontFamily1;
    }
}
.continue-btn {
    width: 100%;
    border-radius: 1.5rem;
    height: 44px;
    padding: 0.5rem 2rem !important;
    background: $secondary1 !important;
    color: white;
    .v-btn__content {
    font-size: 16px;
    line-height: 28px;
    }
}
.content-left {
    flex: 1;
}
.content-right {
    min-width: 400px;
    display: flex;
    justify-content: flex-end;
    padding-top: 80px;
}
.illustration-container {
    height: 395px;
    background: rgb(229, 229, 229);
    width: 350px;
    margin-top: 40px;
}
.illustration{
}
@media screen and (max-width: 900px) {
    .content-main-inner-inner {
        flex-direction: column;
        align-items: center;
    }
    .content-main-actions {
        justify-content: center !important;
    }
}
@media screen and (max-width: 600px) {
  .content-main {
    margin-left: 5.5rem;
  }
  .main {
    padding: 2rem;
  }
  .nav-header .text .legend {
    font-size: 30px;
    line-height: 28px;
  }
  .note-heading{
    width:300px;
  }
  .nav-header .text .subtext {
    font-size: 16px;
    line-height: 28px;
  }
  .rowDisplay {
    display: block;
  }
  .nav-header-icon {
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .continue-btn-wrapper{
    /* max-width: 285px; */
    min-width: 300px;
    padding-right: 10px;
  }
  .action-container {
    align-items: center;
  }
  .greeting-text {
    font-family: $fontFamily2;
    width:312px;
    font-size: 30px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #262626;
  }
  .note-item-text{
    width:300px;
  }
  .footer{
    width:354px;
    margin-left:-1rem;
  }
  .continue-btn{
    width:345px;
    margin-left: -5.2rem;
  }
}
</style>
