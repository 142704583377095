import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"bs-container pa-0",attrs:{"fluid":""}},[_c('OnboardingNavBar'),_c('div',{staticClass:"content-main"},[_c('div',{staticClass:"content-main-info"},[_c('div',{staticClass:"content-main-inner-inner"},[_c('div',{staticClass:"content-left"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"greeting-text"},[_vm._v(" Thanks for applying to the network ")]),_c('div',{staticClass:"note-item"},[_c('div',{staticClass:"note-item-text"},[_vm._v(" Looks like your talent was not quite what we were looking for this round. We will let you know when you can reapply again. ")])]),_c('div',{staticClass:"note-item"},[_c('div',{staticClass:"note-item-text"},[_vm._v(" For any other questions, look at our FAQ’s below or reach out to hello@blendedsense.com. ")])]),_c('div',{staticClass:"content-main-actions d-flex"},[_c('div',{staticClass:"action-container"},[_c('div',{staticClass:"continue-btn-wrapper d-flex flex-column"},[_c('a',{staticClass:"continue-btn",attrs:{"href":"mailto:hello@blendedsense.com"}},[_vm._v(" Contact ")])])])])])]),_c('div',{staticClass:"content-right"},[_c(VImg,{attrs:{"src":require("@/assets/svg/not-a-good-match.svg"),"contain":"","width":"234","height":"191"}})],1)]),_c(VRow,{staticClass:"faq"},[_c(VCol,{key:_vm.questions.length,staticClass:"questions",attrs:{"sm":"12","md":"8","lg":"8","xl":"8"}},[_c('p',{staticClass:"section-title"},[_vm._v("Frequently Asked Questions")]),_vm._l((_vm.questions),function(item,i){return _c(VExpansionPanels,{key:i,staticClass:"description que-description"},[_c(VExpansionPanel,{staticClass:"que-div"},[_c(VExpansionPanelHeader,{staticClass:"text"},[_vm._v(" "+_vm._s(item.question)+" ")]),_c(VExpansionPanelContent,{staticClass:"sub-text expansion-panel-sub-text"},[_vm._v(" "+_vm._s(item.answer)+" ")])],1)],1)})],2)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }